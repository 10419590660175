import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { User } from '@models/User';
import { RoleValidator } from '@helpers/roleValidator';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends RoleValidator {
  public user$: Observable<User>;

  constructor(public afAuth: AngularFireAuth, private afStore: AngularFirestore) {
    super();
    this.user$ = this.afAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return this.afStore.doc<User>(`users/${user.uid}`).valueChanges();
        }
        return of(null);
      })
    );
  }

  async login(data): Promise<User>  {
    try {
      const { user } = await this.afAuth.signInWithEmailAndPassword(data.email, data.password);
      return user;
    } catch (error) {
      console.error(error);
    }
  }

  // tslint:disable-next-line: typedef
  async register(data) {
    const result = await this.afAuth.createUserWithEmailAndPassword(data.email, data.password)
      .then(res => {
        res.user.updateProfile({
          displayName: data.fullname
        });

        this.updateUserData(res.user);

        res.user.sendEmailVerification()
        .catch(error => {
          console.error(error);
        });

        this.logout();
      })
      .catch(error => {
        console.error(error);
      });
    return result;
  }

  // tslint:disable-next-line: typedef
  async verifyEmail(code) {
    await this.afAuth.applyActionCode(code)
    .then(res => {
      return res;
    })
    .catch(error => {
      console.error(error);
    });
  }

  // tslint:disable-next-line: typedef
  async resetPassword(email: string) {
    try {
      return this.afAuth.sendPasswordResetEmail(email);
    } catch (error) {
      console.log(error);
    }
  }

  // tslint:disable-next-line: typedef
  async logout() {
    try {
      await this.afAuth.signOut();
    } catch (error) {
      console.error(error);
    }
  }

  // tslint:disable-next-line: typedef
  private updateUserData(user: User) {
    const userRef: AngularFirestoreDocument<User> = this.afStore.doc(
      `users/${user.uid}`
    );

    const data: User = {
      uid: user.uid,
      email: user.email,
      emailVerified: user.emailVerified,
      displayName: user.displayName,
      address: user?.address,
      city: user?.city,
      photoURL: user.photoURL,
      terms: user.terms,
      role: 'BUYER',
    };

    return userRef.set(data, { merge: true });
  }

  // tslint:disable-next-line: typedef
  getCurrentUser() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }
}
