import { AuthService } from '@services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private authService: AuthService) {}

  canActivate(): Observable<boolean> | Promise<boolean> {
    return this.authService.user$.pipe(
      take(1),
      map((user) => user && this.authService.isBuyer(user)),
      tap((buyer) => {
        if (!buyer) {
          window.alert('Access denied.');
        }
      })
    );
  }
}
