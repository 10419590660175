<header class="site-header position-relative sticky-top">
	<div class="container">
		<div class="row justify-content-center align-items-center position-relative">
			<div class="col-sm-3 col-6 col-lg-2 col-xl-2 order-lg-1">
				<!-- Brand Logo -->
				<div class="brand-logo">
					<a href="#"><img src="assets/image/logo_mc.png" alt="Logo MercadosClik" /></a>
				</div>
			</div>
			<div class="col-sm-8 col-lg-2 col-xl-2 d-none d-sm-block order-lg-3">
				<div class="header-btns justify-content-end">
					<a href="/account" class="submit-btn btn btn-link btn--hover-shine">Iniciar Sesión</a>
				</div>
			</div>
			<!-- Menu Block -->
			<div class="col-sm-1 col-6 col-lg-6 col-xl-6 offset-lg-2 position-static order-lg-2">
				<app-top-menu></app-top-menu>
			</div>
		</div>
	</div>
	<div class="shape-holder header-shape" data-aos="fade-down" data-aos-once="true">
		<img src="assets/image/header-shape.svg" alt="">
	</div>
</header>
