<div class="main-navigation">
	<ul class="main-menu">
		<li class="menu-item has-dropdown">
			<a href="#somos">Somos</a>
				<!--ul class="menu-dropdown">
					<li class="single-item">
						<a href="">
							<h3>Mobile Application</h3> 
							<p>Best for Mobile App Presentation</p>
						</a>
					</li>
					<li class="single-item">
						<a href="">
							<h3>Web Application</h3>
							<p>Best for Mobile App Presentation</p>
						</a>
					</li>
					<li class="single-item">
						<a href="">
							<h3>SaaS Application</h3>
							<p>Best for Mobile App Presentation</p>
						</a>
					</li>
					<li class="single-item">
						<a href="">
							<h3> SaaS Subscription</h3>
							<p>Best for Mobile App Presentation</p>
						</a>
					</li>
				</ul-->
		</li>
		<li class="menu-item "><a href="#precios">Precios</a></li>

		<li class="menu-item"><a href="/contacto">Contacto</a></li>
	</ul>
</div>
<div class="mobile-menu"></div>
