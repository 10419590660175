import { Component, OnInit } from '@angular/core';
// import $ from 'jquery';
declare var $: any;

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.css']
})
export class TopMenuComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {
    // ======>  Mobile Menu Activation
    $('.main-navigation').meanmenu({
      meanScreenWidth: '992',
      meanMenuContainer: '.mobile-menu',
      meanMenuClose: '<i class=\'icon icon-simple-remove\'></i>',
      meanMenuOpen: '<i class=\'icon icon-menu-34\'></i>',
      meanExpand: '',
    });
  }

}
