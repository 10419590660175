<!-- Footer Section -->
<div class="footer-section position-relative">
	<div class="container margin-decrese">
		<div class="row mt--70">
			<div class="col-lg-5 col-xl-5 col-sm-6 col-margin">
				<div class="row">
					<div class="col-lg-5 col-4">
						<ul class="footer-list">
							<li><a href="#somos">Somos</a></li>
							<li><a href="#precios">Susbcripción</a></li>
							<li><a href="/account">Registro</a></li>
							<li><a href="/account">Login</a></li>
						</ul>
					</div>

					<div class="col-lg-7 col-8">
						<ul class="footer-list">
							<li><a href="/contacto">Contacto</a></li>
							<li><a href="#faq">FAQ</a></li>
							<li><a href="/politicos">Políticas de Privacidad</a></li>
							<li><a href="/terminos">Términos &amp; Condiciones</a></li>
						</ul>
					</div>

					<div class="col-lg-12 col-12">
						<span class="ft-title-sm">Todo los derechos reservados © 2020 Lulo Digital Latam</span>
					</div>
				</div>
			</div>
			<!-- <div class="col-lg-7 col-xl-7 col-margin">
				<div class="ft-newsletter-box">
					<span class="ft-title-sm">Newsletter</span>
					<p>
					Suscríbete para recibir las últimas ofertas, noticias y anuncios. No será spam en su bandeja de entrada.</p>
					<form action="">
						<div class="form-group">
							<input type="text" class="form-control" placeholder="Correo electrónico">
							<button class="submit-btn"><i class="icon icon-tail-right"></i></button>
						</div>
					</form>
				</div>
			</div> -->
		</div>
	</div>
	<div class="shape-holder footer-shape-1" data-aos="zoom-in-left" data-aos-once="true"><img src="assets/image/cta-shape.svg" alt=""></div>
	<div class="shape-holder footer-shape-2" data-aos="zoom-in-right" data-aos-once="true"><img src="assets/image/footer-shape.svg" alt=""></div>
</div>
