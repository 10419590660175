import { User } from '@models/User';

export class RoleValidator {
  isBuyer(user: User): boolean {
    return user.role === 'BUYER';
  }

  isSeller(user: User): boolean {
    return user.role === 'SELLER';
  }

  isAdmin(user: User): boolean {
    return user.role === 'ADMIN';
  }
}
