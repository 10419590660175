import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';

import { TopMenuModule } from '../top-menu/top-menu.module';

@NgModule({
  declarations: [HeaderComponent, FooterComponent],
  imports: [
    CommonModule,
    TopMenuModule
  ],
  exports: [HeaderComponent, FooterComponent]
})
export class LayoutModule { }
